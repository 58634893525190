import Vue from 'vue'
import Router from 'vue-router'
import store from './store/index.js'
import DashboardLayout from '@/layout/DashboardLayout'
import AuthLayout from '@/layout/AuthLayout'
import HomeLayout from '@/layout/HomeLayout'
Vue.use(Router)


const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isLoggedIn) {
    next()
    return
  }
  next('/')
}

const ifAuthenticated = (to, from, next) => {  
  
  if (store.getters.isLoggedIn) {   
    next()
    return
  }
  next('/login')
}

export default new Router({
  linkExactActiveClass: 'active',   
  mode: "history",
  routes: [
    {
      path: '/',
      redirect: 'dashboard',
      component: DashboardLayout,    
      children: [
        {
          path: '/dashboard',
          name: 'dashboard',         
          component: () => import(/* webpackChunkName: "demo" */ './views/Dashboard.vue'),
          beforeEnter: ifAuthenticated,
        },
        {
          path: '/kpi',
          name: 'KPI',
          component: () => import(/* webpackChunkName: "demo" */ './views/Admin/Kpi.vue'),
          beforeEnter: ifAuthenticated,
        },
        {
          path: '/productbrand',
          name: 'Product Brand',
          component: () => import(/* webpackChunkName: "demo" */ './views/Admin/ProductBrand.vue'),
          beforeEnter: ifAuthenticated,
        },
        {
          path: '/productcategory',
          name: 'Product Category',
          component: () => import(/* webpackChunkName: "demo" */ './views/Admin/ProductCategory.vue'),
          beforeEnter: ifAuthenticated,
        },
        {
          path: '/product',
          name: 'SKU',
          component: () => import(/* webpackChunkName: "demo" */ './views/Admin/Product.vue'),
          beforeEnter: ifAuthenticated,
        },
        {
          path: '/productcompetitor',
          name: 'Product Competitor',
          component: () => import(/* webpackChunkName: "demo" */ './views/Admin/ProductCompetitor.vue'),
          beforeEnter: ifAuthenticated,
        },
        {
          path: '/outlet',
          name: 'Store',
          component: () => import(/* webpackChunkName: "demo" */ './views/Admin/Outlet.vue'),
          beforeEnter: ifAuthenticated,
        },
        {
          path: '/merchantoutlet',
          name: 'Merchant Outlet',
          component: () => import(/* webpackChunkName: "demo" */ './views/Admin/MerchandiserOutlet.vue'),
          beforeEnter: ifAuthenticated,
        },
        {
          path: '/region',
          name: 'Region',
          component: () => import(/* webpackChunkName: "demo" */ './views/Admin/Region.vue'),
          beforeEnter: ifAuthenticated,
        },
        {
          path: '/user',
          name: 'user',
          component: () => import(/* webpackChunkName: "demo" */ './views/Admin/User.vue'),
          beforeEnter: ifAuthenticated,
        }, 
        {
          path: '/icon',
          name: 'icon',
          component: () => import(/* webpackChunkName: "demo" */ './views/Icons.vue'),
          beforeEnter: ifAuthenticated,
        }, 
        {
          path: '/kpiperformance',
          name: 'KPI Performance',
          component: () => import(/* webpackChunkName: "demo" */ './views/Admin/KpiPerformance.vue'),
          beforeEnter: ifAuthenticated,
        },  
        {
          path: '/channelproduct',
          name: 'MH SKUs',
          component: () => import(/* webpackChunkName: "demo" */ './views/Admin/ChannelProduct.vue'),
          beforeEnter: ifAuthenticated,
        }, 
        {
          path: '/logs',
          name: 'Logs',
          component: () => import(/* webpackChunkName: "demo" */ './views/Admin/Log.vue'),
          beforeEnter: ifAuthenticated,
        },  
        {
          path: '/kpireference',
          name: 'KPI Index Details',
          component: () => import(/* webpackChunkName: "demo" */ './views/Admin/KpiReference.vue'),
          beforeEnter: ifAuthenticated,
        },  
        {
          path: '/report',
          name: 'Report',
          component: () => import(/* webpackChunkName: "demo" */ './views/Admin/Report/Index.vue'),
          beforeEnter: ifAuthenticated,
        },  
        {
          path: '/generalreport',
          name: 'General Report',
          component: () => import(/* webpackChunkName: "demo" */ './views/Admin/Report/Component/GeneralReport.vue'),
          beforeEnter: ifAuthenticated,
        }, 
        {
          path: '/mtgeneralreport',
          name: 'Modern General Report',
          component: () => import(/* webpackChunkName: "demo" */ './views/Admin/Report/Component/MtGeneralReport.vue'),
          beforeEnter: ifAuthenticated,
        }, 
        {
          path: '/mtcardscorereport',
          name: 'MT Score Card Report',
          component: () => import(/* webpackChunkName: "demo" */ './views/Admin/Report/Component/MtScoreCard.vue'),
          beforeEnter: ifAuthenticated,
        }, 
        {
          path: '/gtcardscorereport',
          name: 'GT Score Card Report',
          component: () => import(/* webpackChunkName: "demo" */ './views/Admin/Report/Component/GtScoreCard.vue'),
          beforeEnter: ifAuthenticated,
        }, 
        {
          path: '/modernpickuppricereport',
          name: 'Modern Pick Up Price Report',
          component: () => import(/* webpackChunkName: "demo" */ './views/Admin/Report/Component/ModernPickUpPrice.vue'),
          beforeEnter: ifAuthenticated,
        }, 
        {
          path: '/modernpickuppricegeralreport',
          name: 'General Pick Up Price Report',
          component: () => import(/* webpackChunkName: "demo" */ './views/Admin/Report/Component/GeneralPickUpPrice.vue'),
          beforeEnter: ifAuthenticated,
        }, 
        {
          path: '/distributor',
          name: 'Distributor',
          component: () => import(/* webpackChunkName: "demo" */ './views/Admin/Distributor.vue'),
          beforeEnter: ifAuthenticated,
        }, 
        {
          path: '/supervisor',
          name: 'Supervisor',
          component: () => import(/* webpackChunkName: "demo" */ './views/Admin/Supervisor.vue'),
          beforeEnter: ifAuthenticated,
        }, 
        {
          path: '/summaryreport',
          name: 'Summary Report',
          component: () => import(/* webpackChunkName: "demo" */ './views/Admin/Report/Component/SummaryReport.vue'),
          beforeEnter: ifAuthenticated,
        },  
        {
          path: '/merchandisersummaryreport',
          name: 'Merchandiser Summary Report',
          component: () => import(/* webpackChunkName: "demo" */ './views/Admin/Report/Component/MerchandiserSummary.vue'),
          beforeEnter: ifAuthenticated,
        },   
        {
          path: '/nationaldetailsreport',
          name: 'National Details Report',
          component: () => import(/* webpackChunkName: "demo" */ './views/Admin/Report/Component/NationalDetails.vue'),
          beforeEnter: ifAuthenticated,
        }, 
        {
          path: '/nationaldetailsmodernreport',
          name: 'Modern National Details Report',
          component: () => import(/* webpackChunkName: "demo" */ './views/Admin/Report/Component/NationalDetailsModern.vue'),
          beforeEnter: ifAuthenticated,
        }, 
        {
          path: '/National-Details-Self-Merchandizing-Report',
          name: 'Self Merchandizing National Details Report',
          component: () => import(/* webpackChunkName: "demo" */ './views/Admin/Report/Component/NationalDetailsSelfMerchandizing.vue'),
          beforeEnter: ifAuthenticated,
        }, 
        {
          path: '/shareofshelfreport',
          name: 'Share of Shelf Report',
          component: () => import(/* webpackChunkName: "demo" */ './views/Admin/Report/Component/ShareofShelf.vue'),
          beforeEnter: ifAuthenticated,
        },  
        {
          path: '/skusummaryreport',
          name: 'SKU Summary Report',
          component: () => import(/* webpackChunkName: "demo" */ './views/Admin/Report/Component/SkuSummary.vue'),
          beforeEnter: ifAuthenticated,
        },  
        {
          path: '/CompetitorPriceComparisonReport',
          name: 'COMPITITOR PRICE COMPARISON REPORT',
          component: () => import(/* webpackChunkName: "demo" */ './views/Admin/Report/Component/CompetitorPriceComparisonReport.vue'),
          beforeEnter: ifAuthenticated,
        },  
        {
          path: '/perfectstore',
          name: 'PERFECT STORE PERFORMANCE REPORT',
          component: () => import(/* webpackChunkName: "demo" */ './views/Admin/Report/Component/PerfectStorePerformance.vue'),
          beforeEnter: ifAuthenticated,
        },  
        {
          path: '/osaperformance',
          name: 'OSA PERFORMANCE REPORT',
          component: () => import(/* webpackChunkName: "demo" */ './views/Admin/Report/Component/OsaPerformance.vue'),
          beforeEnter: ifAuthenticated,
        }, 
        {
          path: '/npdperformance',
          name: 'NPD PERFORMANCE REPORT',
          component: () => import(/* webpackChunkName: "demo" */ './views/Admin/Report/Component/NpdPerformance.vue'),
          beforeEnter: ifAuthenticated,
        }, 
        {
          path: '/outletvisitationperformance',
          name: 'OUTLET VISITATION PERFORMANCE REPORT',
          component: () => import(/* webpackChunkName: "demo" */ './views/Admin/Report/Component/OutletVisitationPerformance.vue'),
          beforeEnter: ifAuthenticated,
        },  
        {
          path: '/planogram-national-details',
          name: 'Plano GramNational Details REPORT',
          component: () => import(/* webpackChunkName: "demo" */ './views/Admin/Report/Component/PlanoGramNationalDetails.vue'),
          beforeEnter: ifAuthenticated,
        }, 
        {
          path: '/osr-general-report',
          name: 'OSR General REPORT',
          component: () => import(/* webpackChunkName: "demo" */ './views/Admin/Report/Component/OSRGeneralReport.vue'),
          beforeEnter: ifAuthenticated,
        },  
        {
          path: '/OutletVisitPermission',
          name: 'Outlet Visit Permission',
          component: () => import(/* webpackChunkName: "demo" */ './views/Admin/PermitOutletVisit.vue'),
          beforeEnter: ifAuthenticated,
        }, 
        {
          path: '/passwordpolicy',
          name: 'PASSWORD POLICY',
          component: () => import(/* webpackChunkName: "demo" */ './views/Admin/PasswordPolicy.vue'),
          beforeEnter: ifAuthenticated,
        },  
        {
          path: '/planogram',
          name: 'Planogram',
          component: () => import(/* webpackChunkName: "demo" */ './views/Admin/Planogram/Index.vue'),
          beforeEnter: ifAuthenticated,
        },  
        {
          path: '/channel-planogram',
          name: 'Channel Planogram',
          component: () => import(/* webpackChunkName: "demo" */ './views/Admin/Planogram/Component/CategoryPlanogram.vue'),
          beforeEnter: ifAuthenticated,
        },   
        {
          path: '/product-planogram',
          name: 'Product Planogram',
          component: () => import(/* webpackChunkName: "demo" */ './views/Admin/Planogram/Component/ProductPlanogram.vue'),
          beforeEnter: ifAuthenticated,
        },        
      ]
    },
    {
      path: '/',
      redirect: 'login',
      component: AuthLayout,
      children: [
        {
          path: '/login',
          name: 'login',
          component: () => import(/* webpackChunkName: "demo" */ './views/Account/Login.vue'),
          beforeEnter: ifNotAuthenticated,
        },       
        {
          path: '/retrievepassword',
          name: 'retrieve password',
          component: () => import(/* webpackChunkName: "demo" */ './views/Account/Retrievepassword.vue'),
          beforeEnter: ifNotAuthenticated,
        },
        {
          path: '/changepassword',
          name: 'change password',
          component: () => import(/* webpackChunkName: "demo" */ './views/Account/Changepassword.vue'),
          beforeEnter: ifNotAuthenticated,
        },   
                
      ]
    },
    {
      path: '/',
      redirect: 'home',
      component: HomeLayout,
      children: [
        {
          path: '/home',
          name: 'home',
          component: () => import(/* webpackChunkName: "demo" */ './views/Home.vue'),
          beforeEnter: ifAuthenticated,
        },       
      ]
    }
  ]
})

