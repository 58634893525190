import axios from "axios";

const report = {
  actions: {   
    GtGeneralReport({ rootState }, {KpiReferenceId,ReportTitle}) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/Report/GtGeneralReport?KpiReferenceId=${KpiReferenceId}&ReportTitle=${ReportTitle}&Username=
                ${rootState.auth.token}`
          )
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    MtGeneralReport({ rootState }, {KpiReferenceId,ReportTitle}) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/Report/MtGeneralReport?KpiReferenceId=${KpiReferenceId}&ReportTitle=${ReportTitle}&Username=
                ${rootState.auth.token}`
          )
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },  
   
    downloadpricepickupreport({ rootState }, KpiReferenceId) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/Report/DownloadPricePickupReport?KpiReferenceId=${KpiReferenceId}&Username=
                ${rootState.auth.token}`
          )
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },   
    downloadpricepickupgeneralreport({ rootState }, KpiReferenceId) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/Report/DownloadPricePickupGeneralReport?KpiReferenceId=${KpiReferenceId}&Username=
                ${rootState.auth.token}`
          )
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },    
    downloadsummaryreport({ rootState }, KpiReferenceId) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/Report/DownloadSummaryReport?KpiReferenceId=${KpiReferenceId}&Username=
                ${rootState.auth.token}`
          )
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },    
    downloadmerchandisersummaryreport({ rootState }, KpiReferenceId) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/Report/DownloadMerchandiserSummaryReport?KpiReferenceId=${KpiReferenceId}&Username=
                ${rootState.auth.token}`
          )
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    NationalDetailsReport({ rootState }, {KpiReferenceId,ReportTitle}) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/Report/NationalDetailsReport?KpiReferenceId=${KpiReferenceId}&ReportTitle=${ReportTitle}&Username=
                ${rootState.auth.token}`
          )
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },     
    NationalDetailsModernReport({ rootState }, {KpiReferenceId,ReportTitle}) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/Report/NationalDetailsModernReport?KpiReferenceId=${KpiReferenceId}&ReportTitle=${ReportTitle}&Username=
                ${rootState.auth.token}`
          )
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },    
    NationalDetailsSelfMerchandizing({ rootState }, {KpiReferenceId,ReportTitle}) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/Report/NationalDetailsSelfMerchandizing?KpiReferenceId=${KpiReferenceId}&ReportTitle=${ReportTitle}&Username=
                ${rootState.auth.token}`
          )
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    downloadshareofshelfreport({ rootState }, KpiReferenceId) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/Report/DownloadShareofShelfReport?KpiReferenceId=${KpiReferenceId}&Username=
                ${rootState.auth.token}`
          )
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    downloadskusummaryreport({ rootState }, KpiReferenceId) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/Report/DownloadSkuSummaryReport?KpiReferenceId=${KpiReferenceId}&Username=
                ${rootState.auth.token}`
          )
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    downloadcompetitorpricecomparisonreport({ rootState }, {OutletId,KpiReferenceId}) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/Report/DownloadCompetitorPriceComparisonReport?OutletId=${OutletId}&KpiReferenceId=${KpiReferenceId}&Username=
                ${rootState.auth.token}`
          )
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    DownloadPerfectStoreReport({ rootState }, KpiReferenceId) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/Report/DownloadPerfectStoreReport?KpiReferenceId=${KpiReferenceId}&Username=
                ${rootState.auth.token}`
          )
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    DownloadOsaPerfomanceReport({ rootState }, KpiReferenceId) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/Report/DownloadOsaPerfomanceReport?KpiReferenceId=${KpiReferenceId}&Username=
                ${rootState.auth.token}`
          )
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    DownloadNpdPerfomanceReport({ rootState }, KpiReferenceId) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/Report/DownloadNpdPerfomanceReport?KpiReferenceId=${KpiReferenceId}&Username=
                ${rootState.auth.token}`
          )
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    DownloadOutletVisitationPerfomanceReport({ rootState }, KpiReferenceId) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/Report/DownloadOutletVisitationPerfomanceReport?KpiReferenceId=${KpiReferenceId}&Username=
                ${rootState.auth.token}`
          )
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    GtScoreCardReport({ rootState }, {KpiReferenceId,ReportTitle}) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/Report/GtScoreCardReport?KpiReferenceId=${KpiReferenceId}&ReportTitle=${ReportTitle}&Username=
                ${rootState.auth.token}`
          )
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    MtScoreCardReport({ rootState }, {KpiReferenceId,ReportTitle}) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/Report/MtScoreCardReport?KpiReferenceId=${KpiReferenceId}&ReportTitle=${ReportTitle}&Username=
                ${rootState.auth.token}`
          )
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    PlanoGramNationalDetails({ rootState }, {KpiReferenceId,ReportTitle}) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/Report/PlanoGramNationalDetails?KpiReferenceId=${KpiReferenceId}&ReportTitle=${ReportTitle}&Username=
                ${rootState.auth.token}`
          )
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    OSRGeneralReport({ rootState }, {KpiReferenceId,ChannelId,ReportTitle}) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/Report/OSRGeneralReport?KpiReferenceId=${KpiReferenceId}&ReportTitle=${ReportTitle}&Username=
                ${rootState.auth.token}&ChannelId=${ChannelId}`
          )
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    GetOverviewReport({ rootState }, KpiReferenceId) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/Report/GetOverviewReport?KpiReferenceId=${KpiReferenceId}&Username=
                ${rootState.auth.token}`
          )
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    GetDashboardGraph() {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/Dashboard/GetDashboardGraph`
          )
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    GetDashboardVisitationGraph() {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/Dashboard/GetDashboardVisitationGraph`
          )
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    GetReportGenerated(context,{page,pageSize}) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/Report/GetReportGenerated?page=
                ${page}&pageSize=${pageSize}`
          )
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
};

export default report;
